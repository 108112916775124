import {Component, inject, OnInit, signal} from '@angular/core';
import {LoadingService, NotificationService, PaginationComponent} from "../../../../core";
import {FormsModule} from "@angular/forms";
import {JobCardService} from "../../services/job-card.service";
import {CommonModule, TitleCasePipe, UpperCasePipe} from "@angular/common";
import { JobCardGetDTO} from "../../interfaces/job-card.entity";
import {catchError, of, tap} from "rxjs";
import {StatusBadgeComponent} from "../../../shared/components/status-badge/status-badge.component";
import {ModalService} from "../../../shared/services/modal.service";
import { JobOperationService } from 'src/app/modules/shared/services/job-operation.service';
import { JobDTO, GetJobOperationDTO } from 'src/app/modules/shared/interface/jop-operation.entity';
import { TimeTrackService } from 'src/app/modules/shared/services/time-track.service';

@Component({
    selector: 'app-job-operation-view',
    imports: [
    FormsModule,
    PaginationComponent,
    TitleCasePipe,
    UpperCasePipe,
    StatusBadgeComponent,
    CommonModule,
   
],
    templateUrl: './job-operation-view.component.html',
    standalone: true,
    styleUrl: './job-operation-view.component.scss'
})
export class JobOperationViewComponent implements OnInit {

    private loading = inject(LoadingService);
    protected jobCardService = inject(JobCardService);
    private notification = inject(NotificationService);
    public modal = inject(ModalService);
    private timetrack = inject(TimeTrackService);
    public jobOperationService = inject(JobOperationService);
    public jobStatuses: string[] = ['pending', 'in progress', 'completed', 'hold'];
    protected jobCardGetDTOS = signal<JobCardGetDTO[]>([]);
    protected GetJobOperationDTO = signal<GetJobOperationDTO[]>([]);
    protected $$jobDTOS = signal<JobDTO[]>([]);
    protected readonly $$totalItems = signal(0);
    protected readonly $$pageNumber = signal(1);
    protected readonly $$itemsPerPage = signal(1);
    
    public reasons: string[] = ['Tea Break', 'Lunch', 'Dinner', 'Meeting', 'Material Issue - Reschedule', 'Artwork Issue - Reschedule', 'Color Issue - Reschedule'];

    protected selectedStatus: string = "";
    protected statusChanged: boolean = false;
    protected selectedReason: string = "";


    protected searchParams = signal({
        pp_number: '',
        status: '',
        department_id: -1,
        page_number: 1,
        items_per_page: 10,
        stage_id: -1,
        start_date: '',
        end_date: ''
    });


    ngOnInit(): void {
        const selectedData = localStorage.getItem('#selectedMachine');
        if (selectedData === null || selectedData === '') {
            this.modal.$$MachineSelector.set(true)
        }
        console.log(selectedData)
        this.selectedStatus = this.jobCardGetDTOS()[0]?.jobStatus;
    }

    checkStatusChange(): void {
        this.statusChanged = this.selectedStatus !== this.jobCardGetDTOS()[0]?.jobStatus;
    }

    protected updateStatus(): void {
    
        this.jobCardService.update(this.jobCardGetDTOS()[0].id, this.selectedStatus).pipe(
            tap(() => {
                this.notification.set({ type: 'success', message: 'Job status updated successfully!' });
                this.statusChanged = false;
                this.fetchOperationData(); 
            }),
            catchError(error => {
                this.notification.set({ type: 'error', message: 'Failed to update job status' });
                console.error(error);
                return of(null);
            })
        ).subscribe();
    }


    getDuration(startedDateTime: string | null, completedDateTime: string | null): string {
            if (!startedDateTime || !completedDateTime) {
                return '-';
            }
    
            const startDate = new Date(startedDateTime);
            const endDate = new Date(completedDateTime);

            const durationMs = endDate.getTime() - startDate.getTime();
            const hours = Math.floor(durationMs / (1000 * 60 * 60));
            const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((durationMs % (1000 * 60)) / 1000);
            
            if (hours > 0) {
                return `${hours} hours ${minutes} minutes`;
            } else if (minutes > 0) {
                return `${minutes} minutes ${seconds} seconds`;
            } else {
                return `${seconds} seconds`;
            }
        }


    public onPageChange(pageNumber: number): void {
        const updatedParams = {
            ...this.searchParams(),
            page_number: Number(pageNumber)
        };
        this.searchParams.set(updatedParams);
        this.fetchOperationData();

    }

    protected onOpenModal(ID: number): void {
        this.modal.$$openModal.set(true)
        this.jobCardService.getById(String(ID)).pipe(
            tap(response => {
                this.loading.set(false);
                this.jobCardGetDTOS.set([response.data]);
            }),
            catchError(error => {
                this.loading.set(false);
                this.notification.set({type: 'error', message: `Failed to load Jon Operation details`});
                console.error(error);
                return of(null);
            })
        ).subscribe();
    }

    selectedJobOperationId: number | null = null;
    timelineId: number | null = null;

    protected onOperationOpenModal(ID: number): void {
        this.selectedJobOperationId = ID;
        this.modal.$$openOpeartion.set(true)
        this.jobOperationService.getById(String(ID)).pipe(
            tap(response => {
                this.loading.set(false);
                this.GetJobOperationDTO.set([response.data]);
                
                const operationTimeArray = this.GetJobOperationDTO()[0].operation_time;
                this.timelineId = operationTimeArray[operationTimeArray.length - 1].id
                console.log('id',this.timelineId)
                
                const jobStatus = this.GetJobOperationDTO()[0]?.jobStatus || '';

            switch (jobStatus) {
                case 'completed':
                    this.isStarted = false;
                    this.isOnHold = false;
                    this.isEnded = true;
                    break;
                case 'in progress':
                    this.isStarted = true;
                    this.isOnHold = false;
                    this.isEnded = false;
                    break;
                case 'pending':
                    this.isStarted = false;
                    this.isOnHold = false;
                    this.isEnded = false;
                    break;
                case 'hold':
                    this.isStarted = true;  
                    this.isOnHold = true;
                    this.isEnded = false;
                    break;
                default:
                    this.isStarted = false;
                    this.isOnHold = false;
                    this.isEnded = false;
                    break;
            }

            }),
            catchError(error => {
                this.loading.set(false);
                this.notification.set({type: 'error', message: `Failed to load Job Operation details`});
                console.error(error);
                return of(null);
            })
        ).subscribe();
    }

    // new added

    public fetchOperationData(): void {
        this.jobOperationService.find(this.searchParams()).pipe(
            tap(response => {
                this.$$jobDTOS.set(response.data.data);
                this.$$totalItems.set(response.data.totalItems);
                this.$$pageNumber.set(response.data.page);
                this.$$itemsPerPage.set(response.data.itemsPerPage);
            }),
            catchError(error => {
                this.notification.set({ type: 'error', message: `Failed to fetch Job Operation data` });
                console.error(error);
                return of(null);
            })
        ).subscribe();
    }

    // time

    isStarted = false;
    isOnHold = false;
    isEnded = false;
    startTime: string = '';
   

    onStart(jobOperationId: number) {
        this.isOnHold = false;
        this.startTime = new Date().toLocaleTimeString();

        this.jobOperationService.update(jobOperationId, 'in progress').pipe(
            tap(() => {
                this.notification.set({ type: 'success', message: 'Job started successfully!' });
                this.fetchOperationData(); 
                this.modal.$$openOpeartion.set(false);
            }),
            catchError(error => {
                this.notification.set({ type: 'error', message: 'Failed to start job' });
                console.error(error);
                return of(null);
            })
        ).subscribe();
    }

    onEnd(jobOperationId: number) {

    this.isStarted = false;

        this.jobOperationService.update(jobOperationId, 'completed').pipe(
            tap(() => {
                this.notification.set({ type: 'success', message: 'Job Completed successfully!' });
                this.isStarted = true;
                this.fetchOperationData(); 
                this.modal.$$openOpeartion.set(false);
            }),
            catchError(error => {
                this.notification.set({ type: 'error', message: 'Failed to Complete job' });
                console.error(error);
                return of(null);
            })
        ).subscribe();
    }

    reason: string = '';

    toggleHold(jobOperationId: number, reason: string) {

        if (!reason) {
            this.notification.set({ type: 'error', message: 'Please select a reason before holding the job.' });
            return;
        }
    
        console.log(reason);

        this.isOnHold = true;
    
        this.timetrack.create(jobOperationId, 'hold', reason).pipe(
            tap(() => {
                this.notification.set({
                    type: 'success',
                    message: 'Job is now on hold' 
                });
                this.isStarted = true;
                this.fetchOperationData();
                this.modal.$$openOpeartion.set(false);
                this.reason = '';
            }),
            catchError(error => {
                this.notification.set({ type: 'error', message: `Failed to update job status` });
                console.error(error);
                return of(null);
            })
        ).subscribe();
    }
    

    resumeJob(Id: number){

        this.isOnHold = false;
        this.timetrack.update(Id, 'in progress').pipe(
            tap(() => {
                this.notification.set({
                    type: 'success',
                    message: 'Job is resumed and in progress!'
                });
                this.isStarted = true;
                this.fetchOperationData();
                this.modal.$$openOpeartion.set(false);
            }),
            catchError(error => {
                this.notification.set({ type: 'error', message: `Failed to update job status` });
                console.error(error);
                return of(null);
            })
        ).subscribe();
    }
    


}
