import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {APIRequestResources, CachedAPIRequest, LoadingService, PaginationResponse} from "../../../core";
import {BehaviorSubject, finalize, Subject, switchMap, tap} from "rxjs";
import {toSignal} from "@angular/core/rxjs-interop";
import {Router} from "@angular/router";
import { TimeTrackDTO } from '../interface/time-track.entity';

@Injectable({
    providedIn: 'root'
})
export class TimeTrackService extends CachedAPIRequest {

    loading = inject(LoadingService);
    private refreshTrigger = new Subject<void>();

    constructor(protected override http: HttpClient, private router: Router) {
        super(http, APIRequestResources.TimeTrackService)
    }


    create = (jobOperationId: number, status: string, reason: string) => {
        return this.post<any>({jobOperationId, status, reason}, {}).pipe(
        );
    }

    update = (jobOperationId: number, status: string) => {

        const options = {suffix: `${jobOperationId}`};
        return this.patch<any>({ status }, options).pipe(
            tap(() => {
                console.log(`Job ${jobOperationId} status updated to ${status}`);
            })
        );
    };
    

    triggerRefresh() {
        this.refreshTrigger.next();
    }

    getRefreshObservable() {
        return this.refreshTrigger.asObservable();
    }

}
