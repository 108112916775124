export enum APIRequestResources {
  AuthService = 'auth',
  EmployeeService = 'employee',
  JobCardService = 'job-card',
  ShipmentService = 'shipment',
  JobSaveService = 'job-card',
  DepartmentService = 'department',
  JobOperationService = 'job-operation',
  TimeTrackService = 'time-track'
}

export enum ERPRequestResources {
  PurchaseOrderService = 'purchase-order',

}
