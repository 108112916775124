<nav class="bg-white rounded-lg mx-6 mt-3 py-2 mb-2 overflow-auto">
    <div class="max-w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div class="grid grid-cols-3 h-16 items-center">
            <!-- Left Column -->
            <div class="flex gap-4">
                @if (activeButton === 'purchase-orders') {
                    <div class="relative sm:w-auto">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                            </svg>
                        </div>
                    </div>
                }
            </div>

            <!-- Center Column -->
            <div class="flex justify-center items-center">
                <div class="hidden md:flex items-center space-x-4">
                    @for (button of buttons; track button.key) {
                        <button
                                class="nav-button px-3 py-2 whitespace-nowrap"
                                [ngClass]="{'bg-gray-100': activeButton === button.endpoint}"
                                (click)="navigate(button.endpoint)">
                            {{ button.label }}
                        </button>
                    }
                </div>
            </div>

            <!-- Right Column -->
            <div class="flex justify-end">
                <button type="button" class="button-blue" (click)="logout()">
                    Exit
                </button>
            </div>
        </div>
    </div>
</nav>
