import {Component, inject, OnInit, signal} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {CommonModule} from "@angular/common";
import {filter} from "rxjs";
import {AuthService} from "../../services";
import {ModalService} from "../../../modules/shared/services/modal.service";
import {FormsModule} from "@angular/forms";

@Component({
    selector: 'app-header',
    imports: [
        FormsModule,
        CommonModule
    ],
    templateUrl: './app-header.component.html',
    standalone: true,
    styleUrl: './app-header.component.scss'
})
export class AppHeaderComponent implements OnInit {


  activeButton = '';
  authService = inject(AuthService);
  modal = inject(ModalService);
  poNumber = signal('');

  buttons = [
    { label: 'Job Card Upload', endpoint: 'job-card-upload', key: 'job-card-upload' },
    { label: 'Create Operation', endpoint: 'operation-create', key: 'operation-create' },
    { label: 'Job Operation', endpoint: 'job-operation', key: 'job-operation' }, 
  ];

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.setActiveButton();
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.setActiveButton();
    });
  }

  setActiveButton() {
    const currentRoute = this.router.url.split('/')[1];
    this.activeButton = currentRoute || '';
  }

  navigate(endpoint: string) {
    this.router.navigate([endpoint]);
  }

  logout() {
    this.authService.signOut();
  }

}