import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {APIRequestResources, CachedAPIRequest, LoadingService, PaginationResponse} from "../../../core";
import {BehaviorSubject, finalize, Subject, switchMap, tap} from "rxjs";
import {toSignal} from "@angular/core/rxjs-interop";
import {Router} from "@angular/router";
import {JobCardFindDTO, JobCardGetDTO} from "../interfaces/job-card.entity";

@Injectable({
    providedIn: 'root'
})
export class JobCardService extends CachedAPIRequest {

    loading = inject(LoadingService);
    private refreshTrigger = new Subject<void>();

    $all = new BehaviorSubject<JobCardFindDTO[]>([]);
    all = toSignal(this.$all, {initialValue: []});

    $active = new BehaviorSubject<any | undefined>(undefined);
    active = toSignal(this.$active, {initialValue: undefined});

    private readonly $statistics = new BehaviorSubject<any>(undefined)
    stat = toSignal(this.$statistics, {initialValue: undefined})

    constructor(protected override http: HttpClient, private router: Router) {
        super(http, APIRequestResources.JobCardService)
    }

    find = (searchParams: any, refresh = true) => {
        this.loading.set(true, { immediate: true });
        return this.get<PaginationResponse<JobCardFindDTO[]>>(
            {endpoint: `find`, params: searchParams,},
            refresh ? 'freshness' : 'performance'
        ).pipe(
            tap((res) => {
                this.$all.next(res.data.data);
            }),
            tap((res) => {
                const {data, ...obj} = res.data;
                this.$statistics.next(obj);
            }),
            finalize(() => {
                this.loading.set(false);
            })
        );
    };

    getById = (id: string, refresh = true) => {
        return this.get<JobCardGetDTO>({id}, refresh ? 'freshness' : 'performance')
            .pipe(tap((res) => this.$active.next(res.data)),);
    }

    create = (jobs: any) => {
        return this.post<any>(jobs, {}).pipe(
        );
    }

    update = (id: number, status: string) => {

        const options = {suffix: `update/${id}`};
        return this.patch<any>({ status }, options).pipe(
            tap(() => {

            })
        );
    };
    

    triggerRefresh() {
        this.refreshTrigger.next();
    }

    getRefreshObservable() {
        return this.refreshTrigger.asObservable();
    }

}
