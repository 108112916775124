import {Injectable, signal} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    public $$MachineSelector = signal(false);
    public $$openModal = signal(false);
    public $$openOpeartion = signal(false);
    public $$showFileUploadModal = signal(false);
    public $$showAddJobModal = signal(false);

}