import { inject, Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {APIRequestResources, CachedAPIRequest, LoadingService, PaginationResponse} from "../../../core";
import {Router} from "@angular/router";
import { BehaviorSubject, finalize, Subject, tap } from 'rxjs';
import {GetJobOperationDTO, JobDTO, JobOperationItemDTO} from '../interface/jop-operation.entity';
import { toSignal } from '@angular/core/rxjs-interop';


@Injectable({
  providedIn: 'root'
})
export class JobOperationService extends CachedAPIRequest {

      loading = inject(LoadingService);
      private refreshTrigger = new Subject<void>();
  
      $all = new BehaviorSubject<JobDTO[]>([]);
      all = toSignal(this.$all, {initialValue: []});

      $allOp = new BehaviorSubject<JobOperationItemDTO[]>([]);
      allOp = toSignal(this.$allOp, {initialValue: []});
  
      $active = new BehaviorSubject<any | undefined>(undefined);
      active = toSignal(this.$active, {initialValue: undefined});
  
      private readonly $statistics = new BehaviorSubject<any>(undefined)
      stat = toSignal(this.$statistics, {initialValue: undefined})

  constructor(protected override http: HttpClient, private router: Router) {
    super(http, APIRequestResources.JobOperationService)
  }

  create = (jobOperation: any) => {
    return this.post<any>(jobOperation, {}).pipe(
    );
}

getById = (id: string, refresh = true) => {
    return this.get<GetJobOperationDTO>({id}, refresh ? 'freshness' : 'performance')
        .pipe(tap((res) => this.$active.next(res.data)),);
}

find = (searchParams: any, refresh = true) => {
        this.loading.set(true, { immediate: true });
        return this.get<PaginationResponse<JobDTO[]>>(
            {endpoint: `find`, params: searchParams,},
            refresh ? 'freshness' : 'performance'
        ).pipe(
            tap((res) => {
                this.$all.next(res.data.data);
            }),
            tap((res) => {
                const {data, ...obj} = res.data;
                this.$statistics.next(obj);
            }),
            finalize(() => {
                this.loading.set(false);
            })
        );
    };

    search = (searchParams: any, refresh = true) => {
        this.loading.set(true, { immediate: true });
        return this.get<PaginationResponse<JobOperationItemDTO[]>>(
            {endpoint: `search`, params: searchParams,},
            refresh ? 'freshness' : 'performance'
        ).pipe(
            tap((res) => {
                this.$allOp.next(res.data.data);
            }),
            tap((res) => {
                const {data, ...obj} = res.data;
                this.$statistics.next(obj);
            }),
            finalize(() => {
                this.loading.set(false);
            })
        );
    };

    triggerRefresh() {
        this.refreshTrigger.next();
    }

    getRefreshObservable() {
        return this.refreshTrigger.asObservable();
    }

    update = (jobOperationId: number, status: string) => {

        const options = {suffix: `${jobOperationId}`};
        return this.patch<any>({ status }, options).pipe(
            tap(() => {
                console.log(`Job ${jobOperationId} status updated to ${status}`);
            })
        );
    };


}
