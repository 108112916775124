<script src="job-operation-view.component.ts"></script>
<div class="main">
    <div class="overflow-x-auto">

        <div class="flex flex-nowrap items-center space-x-2 overflow-x-auto pb-4 bg-white w-full">
            <div class="relative  sm:w-auto ">
                <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg class="w-4 h-4 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="0 0 20 20">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                </div>
                <input type="search" [(ngModel)]="searchParams().pp_number"
                       class="focus:ring-gray-100  focus:border-gray-300 p-2.5  block w-full pl-10 pr-3 py-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                       placeholder="PP Number">
            </div>
            <div class="relative">
                <select [(ngModel)]="searchParams().status"
                    class="focus:ring-gray-100 focus:border-gray-300 p-2.5 block w-full text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50">
                    <option value="">All Statuses</option>
                    <option *ngFor="let status of jobStatuses" [value]="status">{{ status | titlecase }}</option>
                </select>
            </div>
            <div class="flex-shrink-0">
                <button (click)="fetchOperationData()" type="submit"
                        class="button-blue  inline-flex items-center whitespace-nowrap">
                    <span class="hidden sm:inline">Search</span>
                </button>
            </div>
        </div>

        <div class="table-wrapper overflow-x-auto">
            <table class="table">
                <thead class="table-thead">
                <tr>
                    <th scope="col" class="px-6 py-3">Job No</th>
                    <th scope="col" class="px-6 py-3">Quantity</th>
                    <th scope="col" class="px-6 py-3">Job Status</th>
                    <th scope="col" class="px-6 py-3">Delivery Date</th>
                    <th style="width: 50%;" scope="col" class="px-6 py-3">Machine Sequence</th>
                    <th scope="col" class="px-6 py-3">Action</th>
                </tr>
                </thead>
                <tbody>
                    @for (jobs of $$jobDTOS(); track jobs) {
                            <tr class="bg-white border-b">
                                <td class="px-6 py-2 text-sm">{{ jobs.jobNo| uppercase }}
                                <td class="px-6 py-2 text-sm"><span>{{ jobs.quantity }}</span>
                                <td class="px-6 py-2 text-sm"><app-status-badge [jobStatus]="jobs.status"></app-status-badge></td>
                                <td class="px-6 py-2 text-sm"><span>{{ jobs.deliveryDate | date:'yyyy-MM-dd':'UTC' }}</span>
                                <td class="px-6 py-2 text-sm">
                                    @for (operation of jobs.operations; track operation.sequence){
                                        <span
                                        (click)="onOperationOpenModal(operation.jobOperationId)"
                                        class="inline-flex flex-col text-xs text-white rounded-md px-2 py-1 mr-1"
                                        [ngClass]="{
                                            'bg-green-500': operation.operationStatus === 'completed',
                                            'bg-sky-600': operation.operationStatus === 'in progress',
                                            'bg-gray-500': operation.operationStatus === 'pending',
                                            'bg-red-600': operation.operationStatus === 'hold'
                                        }">
                                        <span>{{ operation.stageName }}</span>
                                        <span>{{ operation.estimatedDateTime | date:'yyyy-MM-dd':'UTC'}}</span>
                                        <span>{{ operation.operationStatus }}</span>
                                    </span>  
                                    }
                                    <!-- <ng-container *ngFor="let operation of jobs.operations || []">
                                        <span
                                            (click)="onOperationOpenModal(operation.jobOperationId)"
                                            class="inline-flex flex-col text-xs text-white rounded-md px-2 py-1 mr-1"
                                            [ngClass]="{
                                                'bg-green-500': operation.operationStatus === 'completed',
                                                'bg-sky-600': operation.operationStatus === 'in progress',
                                                'bg-gray-500': operation.operationStatus === 'pending',
                                                'bg-red-600': operation.operationStatus === 'hold'
                                            }">
                                            <span>{{ operation.stageName }}</span>
                                            <span>{{ operation.estimatedDateTime | date:'yyyy-MM-dd':'UTC'}}</span>
                                            <span>{{ operation.operationStatus }}</span>
                                        </span>
                                    </ng-container> -->
                                </td>
                                     
                                <td class="px-6 py-4" (click)="onOpenModal(jobs.id)" >
                                    <svg width="20" height="20" class="cursor-pointer" viewBox="0 0 20 20" fill="none">
                                        <path d="M11 14H10V10H9M10 6H10.01M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10Z"
                                              stroke="#1976D2" stroke-width="2" stroke-linecap="round"
                                              stroke-linejoin="round"/>
                                    </svg>
                                </td>
    
                            </tr>
                        
                    } @empty {
                        <tr>
                            <td colspan="12" class="px-6 py-10">
                                <div class="flex flex-col items-center justify-center text-center">
                                    <p class="text-xl font-semibold text-gray-700 mb-2">No Jobs
                                        Found</p>
                                    <p class="text-gray-500">We couldn't find any shipments that match
                                        your search criteria.</p>
                                </div>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
        <app-pagination [totalItems]="$$totalItems()" [itemsPerPage]="$$itemsPerPage()"
                        (pageChanged)="onPageChange($event)"></app-pagination>
    </div>
</div>


@if (modal.$$openModal()) {
    <div id="crud-modal" tabindex="-1" aria-hidden="true" 
        class=" fixed inset-0 z-50 flex justify-center items-center overflow-y-auto bg-gray-900 bg-opacity-50">
        <div class="relative p-4 w-full max-w-5xl mx-auto bg-white rounded-lg shadow-lg">
            <div class="relative">
                <div class="flex items-center justify-between p-4 border-b rounded-t">
                    <h3 class="text-lg font-semibold text-gray-900 flex items-center">
                        <span>Job Card Operations</span>
                        <span class="status-badge ml-4"></span>
                    </h3>

                    <button type="button" (click)="modal.$$openModal.set(false)"
                            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 flex justify-center items-center"
                            data-modal-toggle="crud-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <div class="pt-4">
                    <div class="mb-6 p-6 bg-gray-50 shadow rounded-lg">
                        <div class="grid grid-cols-2 gap-4">
                            <div class="flex items-center text-sm">
                                <span class="font-semibold text-gray-600 w-1/2">Job Number</span>
                                <span class="text-gray-700 ">{{ jobCardGetDTOS()[0].jobNumber }}</span>
                            </div>
                            <div class="flex items-center text-sm">
                                <span class="font-semibold text-gray-600 w-1/2">Job Quantity:</span>
                                <span class="text-gray-700">{{ jobCardGetDTOS()[0].jobQuantity }}</span>
                            </div>
                            <div class="flex items-center text-sm">
                                <span class="font-semibold text-gray-600 w-1/2">No of Breakdowns:</span>
                                <span class="text-gray-700">{{ jobCardGetDTOS()[0].numberOfBreakdowns }}</span>
                            </div>
                            <div class="flex items-center text-sm">
                                <span class="font-semibold text-gray-600 w-1/2">Customer Request Date:</span>
                                <span class="text-gray-700">{{ jobCardGetDTOS()[0].requestDate }}</span>
                            </div>
                            
                        </div>
                        <div class="flex items-start mt-4 mb-4 text-sm">
                            <span class="font-semibold text-gray-600 w-1/3">Job Description:</span>
                            <span class="text-gray-700 w-full">{{ jobCardGetDTOS()[0].jobDescription }}</span>
                        </div>
                        <div class="flex items-start mb-2 text-sm">
                            <span class="font-semibold text-gray-600 w-1/3">Finishings:</span>
                            <span class="text-gray-700 w-full">{{ 'PRINTING, ' + jobCardGetDTOS()[0].finishing }}</span>
                        </div>
                        <div class="flex items-start mb-2 text-sm">
                            <span class="font-semibold text-gray-600 w-1/3">Job Status:</span>
                            <span class="text-gray-700 w-full">{{ jobCardGetDTOS()[0].jobStatus }}</span>
                        </div>
                        <div class="flex items-start mb-2 mt-4 text-sm"> 
                            <span class="font-semibold text-gray-600 w-1/4">Change Job Status:</span>
                            
                            <select class="focus:ring-gray-100 focus:border-gray-300 block text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50"
                                [(ngModel)]="selectedStatus"
                                (change)="checkStatusChange()">
                                <!-- <option [value]="jobCardGetDTOS()[0].jobStatus">
                                    {{ jobCardGetDTOS()[0].jobStatus | titlecase }}
                                </option> -->
                                <option *ngFor="let status of jobStatuses" [value]="status">
                                    {{ status | titlecase }}
                                </option>
                            </select>
                        
                            <button class="button-main ml-4" [disabled]="!statusChanged" (click)="updateStatus()">
                                Change Status
                            </button>
                        </div>
                        
                    </div>
                    <!-- <div>
                        <table class="table">
                            <thead class="table-thead">
                            <tr>
                                <th scope="col" class="px-6 py-3">Sequence</th>
                                <th scope="col" class="px-6 py-3">Operation Status</th>
                                <th scope="col" class="px-6 py-3">Paused At</th>
                                <th scope="col" class="px-6 py-3">Resume At</th>
                                <th scope="col" class="px-6 py-3">Reason</th>
                            </tr>
                            </thead>
                            <tbody>
                                @for (jobs of jobCardGetDTOS(); track jobs.id){
                                    @for(op of jobs.jobOperations; track op){
                                        <tr class="bg-white border-b">
                                            <td class="px-6 py-2 text-sm">{{ op.sequence }}
                                            <td class="px-6 py-2 text-sm"><app-status-badge [jobStatus]="op.jobStatus"></app-status-badge></td>
                                            <td class="px-6 py-2 text-sm">{{ op.pausedAt | date:'yyyy-MM-dd HH:MM:ss'}}
                                            <td class="px-6 py-2 text-sm">{{ op.resumedAt | date:'yyyy-MM-dd HH:MM:ss' }}
                                            <td class="px-6 py-2 text-sm">{{ op.reason }}
                                        </tr>
                                    }
                                } @empty {
                                    <tr>
                                        <td colspan="12" class="px-6 py-10">
                                            <div class="flex flex-col items-center justify-center text-center">
                                                <p class="text-xl font-semibold text-gray-700 mb-2">No Hold Jobs</p>
                                                <p class="text-gray-500">We couldn't find any paused jobs</p>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
}

@if(modal.$$openOpeartion()){
    <div id="operation-modal" tabindex="-1" aria-hidden="true"
         class=" fixed inset-0 z-50 flex justify-center items-center overflow-y-auto bg-gray-900 bg-opacity-50">
        <div class="relative p-4 w-full max-w-5xl mx-auto bg-white rounded-lg shadow-lg">
            <div class="relative">
                <div class="flex items-center justify-between p-4 border-b rounded-t">
                    <h3 class="text-lg font-semibold text-gray-900 flex items-center">
                        <span>Job Operation Details</span>
                        <span class="status-badge ml-4"></span>
                    </h3>
                    <button type="button" (click)="modal.$$openOpeartion.set(false)"
                            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 flex justify-center items-center"
                            data-modal-toggle="crud-modal">
                        <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                             viewBox="0 0 14 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                        </svg>
                        <span class="sr-only">Close modal</span>
                    </button>
                </div>
                <div class="pt-4 ">
                    <div class="mb-2 p-6 bg-gray-50 shadow rounded-lg">
                        <div class="grid grid-cols-2 gap-4">
                            <div class="flex items-center ">
                                <span class="font-semibold text-sm text-gray-600 w-1/2">SPP Number</span>
                                <span class="text-gray-700 text-sm">{{ GetJobOperationDTO()[0].job_details.sppNo }}</span>
                            </div>
                            <div class="flex items-center ">
                                <span class="font-semibold text-sm text-gray-600 w-1/2">Estimate Date and Time</span>
                                <span class="text-gray-700 text-sm">{{ GetJobOperationDTO()[0].estimatedDateTime | date:'yyyy-MM-dd HH:mm:ss' }}</span>
                            </div>
                            <div class="flex items-center ">
                                <span class="font-semibold text-sm text-gray-600 w-1/2">Start Date</span>
                                <span class="text-gray-700 text-sm">{{ GetJobOperationDTO()[0].startedDateTime | date: 'yyyy-MM-dd HH:mm:ss':'UTC'  }}</span>
                            </div>
                            <div class="flex items-center ">
                                <span class="font-semibold text-sm text-gray-600 w-1/2">Completed Date and Time</span>
                                <span class="text-gray-700 text-sm">{{ GetJobOperationDTO()[0].completedDateTime | date:'yyyy-MM-dd HH:mm:ss':'UTC' }}</span>
                            </div>
                            <div class="flex items-center ">
                                <span class="font-semibold text-sm text-gray-600 w-1/2">Operation Status</span>
                                <span class="text-gray-700 text-sm">{{ GetJobOperationDTO()[0].jobStatus | uppercase }}</span>
                            </div>
                            <div class="flex items-center ">
                                <span class="font-semibold text-sm text-gray-600 w-1/2">Job Quantity</span>
                                <span class="text-gray-700 text-sm">{{ GetJobOperationDTO()[0].job_details.quantity}}</span>
                            </div>
                            <div class="flex items-center">
                                <span class="font-semibold text-sm text-gray-600 w-1/2">Duration</span>
                                <span class="text-gray-700 text-sm">
                                    {{ getDuration(GetJobOperationDTO()[0].startedDateTime, GetJobOperationDTO()[0].completedDateTime) }}
                                </span>
                            </div>
                            
                        </div>
                    </div>

                    <div class="py-4">
                        
                            <div class="p-4 bg-gray-50 shadow-md rounded-lg w-full text-center  flex flex-col items-center pb-4">
                                <span class="font-semibold text-sm text-gray-600">Manage Job</span>
                                <div class="rounded-lg mt-4 p-4 bg-green-50 border border-green-200 text-green-600 text-sm mb-2 w-[460px] text-center">
                                    Add a reason when you hold the job!
                                </div>
                                <div class="mt-3 flex flex-row gap-2 items-center">

                                    <input type="search"
                                        class="ring-gray-100 focus:border-gray-300 px-3 py-1.5 block w-48 text-sm text-gray-900 border border-gray-300 rounded-md bg-white text-center"
                                        placeholder="Start Time"
                                        [(ngModel)]="startTime"
                                        disabled>
                            
                                    <button type="button"
                                        class="button-blue px-3 py-1 rounded-md text-white bg-blue-600 hover:bg-blue-700 disabled:bg-gray-400 w-32"
                                        (click)="onStart(selectedJobOperationId!)"
                                        [disabled]="isStarted || isEnded">
                                        Job Start
                                    </button>
                            
                                    <button type="button"
                                        class="button-red px-3 py-1 rounded-md text-white bg-red-600 hover:bg-red-700 disabled:bg-gray-400 w-32"
                                        (click)="onEnd(selectedJobOperationId!)"
                                        [disabled]="!isStarted || isOnHold || isEnded">
                                        Job End
                                    </button>
                                </div>
                                <div class="mt-3 flex flex-row gap-4 items-center mb-4">
                                    <select
                                        [(ngModel)]="selectedReason"
                                        class="focus:ring-gray-100 focus:border-gray-300 p-2 block w-80 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50">
                                        <option value="" disabled >Select a Reason</option>
                                        <option *ngFor="let reason of reasons" [value]="reason">{{ reason | titlecase }}</option>
                                    </select>
                                    <button type="button"
                                        class="button-red px-3 py-1 rounded-md text-white bg-red-600 hover:bg-red-700 disabled:bg-gray-400 w-32"
                                        (click)="isOnHold ? resumeJob(timelineId!) : toggleHold(selectedJobOperationId!, selectedReason)"
                                        [disabled]="!isStarted || isEnded">
                                        {{ isOnHold ? 'Resume Job' : 'Hold Job' }}
                                    </button>
                                </div>
                                
                            </div>
                            
                       
                    </div>

                    <div>
                        <table class="table">
                            <thead class="table-thead">
                            <tr>
                                <th scope="col" class="px-6 py-3">Paused At</th>
                                <th scope="col" class="px-6 py-3">Resume At</th>
                                <th scope="col" class="px-6 py-3">Reason</th>
                            </tr>
                            </thead>
                            <tbody>
                                @for (op of GetJobOperationDTO(); track op){
                                    @for(time of op.operation_time; track time.id){
                                        <tr class="bg-white border-b">
                                            <td class="px-6 py-2 text-sm">{{ time.pausedAt | date: 'yyyy-MM-dd HH:mm:ss' }}
                                            <td class="px-6 py-2 text-sm">{{ time.resumedAt | date: 'yyyy-MM-dd HH:mm:ss' }}
                                            <td class="px-6 py-2 text-sm">{{ time.reason }}</td>
                                        </tr>
                                    }
                                } @empty {
                                    <tr>
                                        <td colspan="12" class="px-6 py-10">
                                            <div class="flex flex-col items-center justify-center text-center">
                                                <p class="text-xl font-semibold text-gray-700 mb-2">No Hold Jobs</p>
                                                <p class="text-gray-500">We couldn't find any paused jobs</p>
                                            </div>
                                        </td>
                                    </tr>
                                }

                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
        </div>
    </div>
}

<!-- @if (modal.$$MachineSelector()){
    <app-device-select></app-device-select>
} -->

