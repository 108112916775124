import {Component, Input} from '@angular/core';
import {NgClass, UpperCasePipe} from "@angular/common";

@Component({
  selector: 'app-status-badge',
  template: `
    <span class="status-badge" [ngClass]="getNormalizedStatus(jobStatus)">
      {{ jobStatus | uppercase }}
    </span>
  `,
  standalone: true,
  imports: [
    NgClass,
    UpperCasePipe
  ],
  styles: [`
    .status-badge {
      @apply inline-flex justify-center whitespace-nowrap items-center max-w-min min-w-24 text-xs font-medium px-2.5 py-0.5 rounded-lg;

      &::before {
        content: '';
        @apply w-1 h-1 me-1 rounded-full;
      }

      &.completed, &.sent, &.paid {
        @apply bg-green-100 text-green-800;
        &::before {
          @apply bg-green-500;
        }
      }

      &.unavailable {
        @apply bg-gray-100 text-gray-800;
        &::before {
          @apply bg-gray-500;
        }
      }

      &.hold, &.not_received, &.rejected {
        @apply bg-red-100 text-red-800;
        &::before {
          @apply bg-red-600;
        }
      }

      &.in-progress, &.credit, {
        @apply bg-sky-100 text-sky-800;
        &::before {
          @apply bg-sky-600;
        }
      }

      &.pending {
        @apply bg-zinc-100 text-zinc-800;
        &::before {
          @apply bg-zinc-400;
        }
      }

      &.sea{
        @apply bg-purple-100 text-[#3E2551];
        &::before {
          @apply bg-[#A183BF];
        }
      }

      &.courier {
        @apply bg-lime-100 text-lime-800;
        &::before {
          @apply bg-lime-500;
        }
      }

      &.air {
        @apply bg-[#FCECCB] text-yellow-700;
        &::before {
          @apply bg-[#AD853E];
        }
      }
    }
  `]
})
export class StatusBadgeComponent {
  @Input() jobStatus: string = '';

  getNormalizedStatus(status: string): string {
    return status.toLowerCase().replace(/\s+/g, '-');
  }
}
